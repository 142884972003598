.Editions {
    width: 100%;
    font-family: Verdana, Arial, Helvetica, sans-serif;
}

.bookItems {
    display: flex;
    flex-flow: row-reverse wrap-reverse;
    justify-content: center;
}

.book {
    margin-top: 2em;
    margin-right: 1em;
    width: 400px;
    display: flex;
    font-size: 12px;
}

.book img {
    max-width: 160px;
}

.book .right {
    padding-left: 10px;
}

.titleList {
    font-size: 14px;
    font-weight: bold;
}

.subtitleList {
    font-size: 12px;
    font-style: italic;
    font-weight: normal;
}

.summary {
    margin-top: 1em;
}

.previewLink {
    font-weight: bold;
}

.bonDeCommande {
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}