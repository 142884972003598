.Edition {
    width: 100%;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 12px;
}

.linkBackToEditions {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    margin-left: 10px;
    margin-top: 10px;
}

table {
    margin: 0 auto;
}

.Edition .cover img {
    max-width: 350px;
}

.Edition .right {
    padding-left: 2em;
}

.titleDetail {
    font-size: 14px;
}

.description {
    text-align: justify;
}

.preview {
    text-align: center;
}

.price {
    font-weight: bold;
    font-size: 14px;
}