.EditionPreview {
    width: 100%;
    font-family: Verdana, Arial, Helvetica, sans-serif;
}

.linkBackToEditions {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    margin-left: 10px;
    margin-top: 10px;
}

.paypal {
    display: flex;
    justify-content: center;
    text-align: center;
}