:root {
    --app-border-color: #776e67;

    --nav-bg-color: #786d69;
    --nav-height: 30px;

    --panorama-width: 970px;
    --panorama-height: 270px;
}

.headerPanorama {
    height: calc(var(--nav-height) + var(--panorama-height));
    position: relative;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-bottom: 1em;

}

/* nav used if panorama */
.navPanorama {
    background: var(--nav-bg-color);
    height: var(--nav-height);
    line-height: var(--nav-height);
    color: white;
    display: flex;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: bold;
}

.navPanorama > a {
    text-decoration: none;
    color: white;
}

.panorama>img {
    opacity: 0;
    animation-name: fade;
    animation-duration: 18s;
    animation-iteration-count: infinite;
    position: absolute;
    top: var(--nav-height);
    left: 0;
}

.panorama>img:nth-child(1) {
    animation-delay: 0s;
}

.panorama>img:nth-child(2) {
    animation-delay: 3s;
}

.panorama>img:nth-child(3) {
    animation-delay: 6s;
}

.panorama>img:nth-child(4) {
    animation-delay: 9s;
}

.panorama>img:nth-child(5) {
    animation-delay: 12s;
}

.panorama>img:nth-child(6) {
    animation-delay: 15s;
}

main {
    padding: 1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

main .editions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

main .editions>p {
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
}

footer {
    font-size: 12px;
    color: #666;
    padding: 1em;
    text-align: center;
}

/* Keyframes */

@keyframes fade {
    0% {
        opacity: 0;
    }

    /** fadeIn **/
    11.11% {
        opacity: 1;
    }

    /** image displayed **/
    33.33% {
        opacity: 1;
    }

    /** image stays 2 secs **/
    44.44% {
        opacity: 0;
    }

    /** fadeOut **/
    100% {
        opacity: 0;
    }
}