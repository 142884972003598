.Header {
    --header-image-height: 173px;
    font-size: 12px;
    margin-bottom: 1em;
    position: relative;
    height: var(--header-image-height);
}

.linkHome {
    position: absolute;
    top: 0;
    width: 230px;
    height: var(--header-image-height);
}

.navHeader {
    height: 22px;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 13px;
    text-decoration: none;
    font-weight: bold;
    width: 100%;
    display: flex;
    justify-content: right;
    position: absolute;
    bottom: 0;
}

.navHeader > a {
    color: white;
    text-decoration: none;
}